import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { TooltipWrapper } from '../../common'
import { Record } from '../../../models'
import { isLocalPhone } from '../../../helpers'
import { getAuthUser } from '../../../helpers'

interface RowProps {
    isSubmitting: boolean
    onSubmit: (oldValue: string, newValue: string) => void
    record: Record
}

export const UnknownSupplierRow = (props: RowProps) => {
    const { isSubmitting, onSubmit, record } = props

    const [input, setInput] = useState(record.phone)
    const [isValid, setIsValid] = useState(true)
    const currentUser = getAuthUser().id.toString()
    const assignedToUser = record.assignedUser ? record.assignedUser.toString() : '';
    const currentUserMatchesAssignedUser = currentUser === assignedToUser;

    return (
        <tr key={record.id}>
            <td><Link to={`/records/${record.id}`}>{record.id}</Link></td>
            <td>{record.status.name}</td>
            <td>{record.supplierName ? record.supplierName : 'N/A'}</td>
            <td>
                <input
                    className='form-control'
                    onChange={(e) => {
                        setInput(e.target.value)
                        setIsValid(isLocalPhone(e.target.value))
                    }}
                    type='text'
                    value={input ?? ''} />
            {!isValid &&
                <div className='invalid-feedback d-block'>
                    Enter local phone number
                </div>
            }
            </td>
            <td>
                <TooltipWrapper
                    condition={record.phone.length === 0}
                    element={
                        <button
                            className='btn btn-primary'
                            disabled={!currentUserMatchesAssignedUser || record.phone.length === 0 || isSubmitting}
                            onClick={() => onSubmit(record.phone, input)}>
                            Update Phone
                        </button>
                    }
                    message='Edit records with missing phone numbers from record page' />
            </td>
        </tr>
    )
}
