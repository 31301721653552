import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Record } from '../../../models'
import { batchUpdateRecords } from '../../../api'
import { PaginatedTableContract } from '../../common'
import { UnknownSupplierRow } from './row'

interface UnknownSupplierProps extends PaginatedTableContract {
    resources: {
        records: Record[]
    }
}

export const UnknownSupplierTable = (props: UnknownSupplierProps) => {
    const { resources, callbacks } = props
    const { records } = resources
    const { setReload } = callbacks

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (oldValue: string, newValue: string) => {
        setIsSubmitting(true)

        const params = {
            phone_number: {
                oldValue,
                newValue
            }
        }

        batchUpdateRecords('unknownSupplier', params)
            .then(response => {
                if (response.status === 200 && setReload) {
                    toast.success('Phone Number Updated')
                    setReload(true)
                }
            })
            .catch(error => {
                const { response } = error
                const { message } = response.data

                if (message) {
                    toast.error(message, { autoClose: false })
                } else {
                    toast.error(response.data, { autoClose: false })
                }
            })
            .finally(() => setIsSubmitting(false))
    }

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Record ID</th>
                    <th>Status</th>
                    <th>Supplier Name</th>
                    <th colSpan={2}>Phone</th>
                </tr>
                {records.map(record =>
                    <UnknownSupplierRow
                        key={record.id}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        record={record} />
                )}
            </tbody>
        </table>
    )
}
