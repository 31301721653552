import React, { useEffect, useState } from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { RecordsTable } from './'
import { JsonRecord, JsonUser, Record, User } from '../../models'
import { getRecords, getUnassignedRecordCount, getUsers } from '../../api'
import { downloadRecordsCsv } from '../../api/routes'
import { getAuthUser } from '../../helpers'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common'
import { RecordStatusFilters } from './filters'
import { AssignRecordsForm } from './assignRecordsForm'
import { AdvancedSearch } from './advancedSearch'
import { toast } from 'react-toastify'

const defaultSearchState = {
    rec: '',
    firstName: '',
    lastName: '',
    conf_no: '',
    phone: '',
    agency: '',
    din: '',
    out: '',
    supplierPhone: '',
    supplierId: '',
    assignedUserId: '',
}

export const RecordsLanding = () => {
    const ability = defineAbilityFor(getAuthUser())
    const [users, setUsers] = useState<User[]>([])
    const searchInputs = [
        { id: 'rec', name: 'rec', placeholder: 'Record ID', type: "text" },
        { id: 'firstName', name: 'firstName', placeholder: 'First Name', type: "text" },
        { id: 'lastName', name: 'lastName', placeholder: 'Last Name', type: "text" },
        { id: 'phone', name: 'phone', placeholder: 'Phone', type: "text" },
        { id: 'conf_no', name: 'conf_no', placeholder: 'Confirmation no.', type: "text" },
        { id: 'agency', name: 'agency', placeholder: 'Agency', type: "text" },
        { id: 'supplierPhone', name: 'supplierPhone', placeholder: 'Supplier Phone', type: "text" },
        { id: 'supplierId', name: 'supplierId', placeholder: 'Supplier ID', type: "text" },
        { id: 'din', name: 'din', placeholder: 'Check in', type: "date", label: "Check in" },
        { id: 'out', name: 'out', placeholder: 'Check out', type: "date", label: "Check out" },
        {
            id: 'assignedUserId',
            name: 'assignedUserId',
            label: 'Select Assigned User',
            type: "dropdown",
            options: [
                ...users.map(user => ({ value: user.id, label: user.name }))
            ]
        }
    ]

    useEffect(() => {
        getUsers()
            .then(json => {
                setUsers(json.data.map((user: JsonUser) => new User(user)))
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }, [])

    if (!ability.can('View', 'Record')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Record, JsonRecord>
            DownloadButton={({ advancedSearch, params }) => {
                const searchParams = new URLSearchParams(params)

                searchParams.set('advancedSearch', JSON.stringify(advancedSearch))

                return (
                    <a href={downloadRecordsCsv + '?' + searchParams.toString()}
                        className="btn btn-primary">
                        Download CSV
                    </a>
                )
            }}
            getData={getRecords}
            Filters={RecordStatusFilters}
            Form={
                ability.can('AssignToUser', 'Record')
                    ? (props) => (
                        <AssignRecordsForm
                            users={users}
                            {...props}
                            getRecordCount={getUnassignedRecordCount}
                            recordType='default'
                        />
                    )
                    : undefined
            }
            redirectLink={{
                to: '/records/add',
                text: 'Create New Record',
            }}
            AdvancedSearchComponent={(props) => (
                <AdvancedSearch
                    users={users}
                    {...props}
                    inputs={searchInputs}
                    defaultState={defaultSearchState}
                />
            )}
            defaultAdvancedSearchState={defaultSearchState}
            modelConstructor={(json: JsonRecord) => new Record(json)}
            disableSearch={true}
            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                <RecordsTable callbacks={callbacks} resources={{ records }} />
            )}
            title="Records"
        />
    )
}
