import React, { useEffect, useState } from 'react'

import { PaginatedTable, PaginatedTableCallbacks, InsufficientPermissions } from '../../common'
import { UnknownSupplierTable } from './table'
import { JsonRecord, Record, JsonUser, User } from '../../../models'
import { getRecordsWithUnknownSupplier, getUnknownSupplierCount, getUsers } from '../../../api'
import { getAuthUser } from '../../../helpers';
import defineAbilityFor from '../defineAbility'
import { RecordStatusFilters } from '../filters'
import { RecordFilters } from './filters'
import { AdvancedSearch } from '../advancedSearch'
import { AssignRecordsForm } from '../assignRecordsForm'
import { toast } from 'react-toastify'
import { downloadUnknownSupplierCsv } from '../../../api/routes'

const defaultSearchState = {
    rec: '',
    supplierName: '',
    recPhone: '',
    agencyId: ''
}

export const UnknownSupplier = () => {
    const ability = defineAbilityFor(getAuthUser())
    const [users, setUsers] = useState<User[]>([])
    const searchInputs = [
        { id: 'rec', name: 'rec', placeholder: 'Record ID', type: "text"},
        { id: 'supplierName', name: 'supplierName', placeholder: 'Supplier Name', type: "text"},
        { id: 'recPhone', name: 'recPhone', placeholder: 'Supplier Phone', type: "text"},
        { id: 'agencyId', name: 'agencyId', placeholder: 'Agency ID', type: 'text'}
    ]

    useEffect(() => {
        getUsers()
            .then(json => {
                setUsers(json.data.map((user: JsonUser) => new User(user)))
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }, [])

    if (!ability.can('View', 'UnknownSupplier')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Record, JsonRecord>
            getData={getRecordsWithUnknownSupplier}
            Filters={(props) => (
                <div className="row">
                    <div className="col-md-5">
                        <RecordFilters {...props} />
                    </div>
                    <div className="col-md-7">
                        <RecordStatusFilters {...props} />
                    </div>
                </div>
            )}
            DownloadButton={ability.can('DownloadCsv', 'UnknownSupplierRecord')
                ? (() => (
                    <a
                        href={downloadUnknownSupplierCsv}
                        className="btn btn-primary"
                        download="unknown_supplier_records.csv"
                    >
                        Download CSV
                    </a>
                ))
                : undefined
            }
            Form={
                ability.can('AssignToUser', 'Record')
                    ? (props) => (
                        <AssignRecordsForm
                            users={users}
                            {...props}
                            getRecordCount={getUnknownSupplierCount}
                            recordType='unknownSupplier'
                        />
                    )
                    : undefined
            }
            AdvancedSearchComponent={(props) => (
                <AdvancedSearch {...props}
                    inputs={searchInputs}
                    defaultState={defaultSearchState} />
            )}
            defaultAdvancedSearchState={defaultSearchState}
            modelConstructor={(json: JsonRecord) => new Record(json)}
            disableSearch={true}
            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                <UnknownSupplierTable
                    callbacks={callbacks}
                    resources={{records}} />
            )}
            title='Records with Unknown Supplier' />
    )
}
