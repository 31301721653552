import React, { useEffect, useState } from 'react'
import { getUser } from '../../../api'
import { User } from '../../../models'
import { getAuthUser } from '../../../helpers'

interface FilterProps {
    updateFilters: (filterName: string, newValue: string, shouldDelete?: boolean) => void
}

export const RecordFilters = (props: FilterProps) => {
    const { updateFilters } = props
    const currentUser = getAuthUser().id.toString()
    const [currentUserData, setCurrentUserData] = useState<User | null>(null)

    useEffect(() => {
        getUser(currentUser)
            .then(json => {
                if (json.data) {
                    setCurrentUserData(new User(json.data))
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error)
            })
    }, [currentUser])

    return (
        <>
            <label htmlFor='assignedUserFilter'>Assigned to:</label>
            <select
                className='form-select'
                id='assignedUserFilter'
                onChange={(e) => {
                    const selectedUserId = e.target.value ?? ''
                    updateFilters('assigned_user_id', selectedUserId, selectedUserId === '-1')
                }}
            >
                <option value={-1}>All</option>
                {currentUserData && (
                    <option value={currentUserData.id}>{currentUserData.name}</option>
                )}
            </select>
        </>
    )
}
