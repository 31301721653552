import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Loading, TooltipWrapper } from '../common'
import { Roles, getAuthUser, userHasRole } from '../../helpers'
import { JsonRole, Role, User } from '../../models'
import {
    getRoles,
    getUser,
    updateUserStatus,
    batchUpdateRecords,
    updateSupplierAssignedUser
} from '../../api'
import defineAbilityFor from './defineAbility'

export const UserCard = () => {
    const [user, setUser] = useState<User>()
    const [reload, setReload] = useState(false)
    const [isEditingRoles, setIsEditingRoles] = useState(false)
    const [roles, setRoles] = useState<Role[]>([])
    const [roleCheckboxes, setRoleCheckboxes] = useState<Record<number, boolean>>({})
    const [isActive, setIsActive] = useState(false)
    const [isUnassigningRecords, setIsUnassigningRecords] = useState(false)
    const [isUnassigningSuppliers, setIsUnassigningSuppliers] = useState(false)

    const params = useParams()
    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        if (params.userId) {
            getUser(params.userId)
                .then(response => {
                    if (response.status === 200) {
                        const userData = new User(response.data)
                        setUser(userData)
                        setIsActive(userData.is_active)
                    }
                })
        }
    }, [params.userId, reload])

    useEffect(() => {
        getRoles()
            .then(response => {
                if (response.status === 200) {
                    setRoles(response.data.map((role: JsonRole) => new Role(role)))
                }
            })
    }, [])

    useEffect(() => {
        if (user instanceof User) {
            let boxes = {}

            roles.forEach(role => {
                boxes = {
                    ...boxes,
                    [role.id]: user.roles.map(role => role.id).includes(role.id)
                }
            })

            setRoleCheckboxes(boxes)
        }
    }, [roles.length, user?.id])


    const handleRoleCheckboxChange = (id: number, checked: boolean) => {
        const role = roles.find(r => r.id === id)
        const canEditExecutiveRole = ability.can('edit', 'ExecutiveRole')

        if (!role) {
            toast.error('Role not found', { autoClose: false })
            return
        }

        if (role.name === Role.EXECUTIVE && !canEditExecutiveRole) {
            toast.error('You do not have permission to assign executive roles', { autoClose: false })
            return
        }

        setRoleCheckboxes({
            ...roleCheckboxes,
            [id]: checked
        })
    }

    const handleActiveToggle = (newActiveState: boolean) => {
        setIsActive(newActiveState)
    }

    if (!(user instanceof User)) {
        return <Fragment />
    }

    const isUserExecutive = userHasRole(user, Roles.Executive)

    const handleStatusUpdateSubmit = () => {
        updateUserStatus(user.id.toString(), { roles: roleCheckboxes, isActive })
            .then(response => {
                if (response.status === 200) {
                    toast.success('User Status Updated Successfully')
                    const updatedUser = new User(response.data)
                    setUser(updatedUser)
                    setIsActive(updatedUser.is_active)
                }
                else {
                    toast.error('An error occurred while updating the user', { autoClose: false })
                    setIsEditingRoles(false)
                }
            })
    }

    const handleUnassignRecords = () => {
        const params = {
            assigned_user_id: {
                oldValue: JSON.stringify({
                    assignedUserId: user.id
                }),
                newValue: ''
            }
        }

        setIsUnassigningRecords(true)

        batchUpdateRecords('default', params)
            .then(json => {
                setReload(true)
                setIsUnassigningRecords(false)
                toast.success('Records Unassigned')
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }

    const handleUnassignSuppliers = () => {
        const params = {
            assigned_user_id: ''
        }

        setIsUnassigningSuppliers(true)

        updateSupplierAssignedUser(params)
            .then(json => {
                setReload(true)
                setIsUnassigningSuppliers(false)
                toast.success('Suppliers Unassigned')
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }

    const isUnassignRecordsDisabled = isUnassigningRecords
        || !ability.can('unassign', 'Records')
        || (isUserExecutive && !ability.can('unassign', 'ExecutiveRecords'))

    const isUnassignSuppliersDisabled = isUnassigningSuppliers 
        || !ability.can('unassign', 'Suppliers')

    const unassignTooltipMessage = isUnassignRecordsDisabled
        ? (isUnassigningRecords
            ? 'Unassignment in progress...'
            : 'Only an executive user can unassign executive records')
        : 'Only collection agents can unassign suppliers'

    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>{user.name}</h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50 align-items-center'>
                        {!isEditingRoles &&
                            <TooltipWrapper
                                condition={!ability.can('edit', 'UserRole')}
                                element={
                                    <button
                                        className='btn btn-primary'
                                        disabled={!ability.can('edit', 'UserRole')}
                                        onClick={() => setIsEditingRoles(true)}>
                                        Edit User
                                    </button>
                                }
                                message='Executive permission required to edit user roles' />
                        }
                        {isEditingRoles &&
                            <Fragment>
                                <button
                                    className='btn btn-primary'
                                    onClick={() => handleStatusUpdateSubmit()}>
                                    Submit
                                </button>
                            </Fragment>
                        }
                    </div>

                </div>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>Email</th>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <th>Roles</th>
                            <td style={{ whiteSpace: 'pre' }}>
                                {!isEditingRoles && user.roles.map(role => role.name + "\n")}
                                {isEditingRoles && roles.map(role => {
                                    const box = `${role.id}_checkbox`

                                    return (
                                        <div className='form-check' key={role.id}>
                                            <input
                                                className='form-check-input'
                                                name={box}
                                                onChange={(e) => handleRoleCheckboxChange(role.id, e.target.checked)}
                                                type='checkbox'
                                                value=''
                                                id={box}
                                                checked={roleCheckboxes[role.id]} />
                                            <label
                                                className='form-check-label'
                                                htmlFor={box}>
                                                {role.name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>Is Active</th>
                            <td>
                                {isEditingRoles &&
                                    <input
                                        type='checkbox'
                                        checked={isActive}
                                        onChange={(e) => handleActiveToggle(e.target.checked)}
                                    />
                                }
                                {!isEditingRoles &&
                                    <span>{isActive ? 'Yes' : 'No'}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Assigned Records</th>
                            <td className='d-flex justify-content-between'>
                                {user.assignedRecordCount}
                                <TooltipWrapper
                                    condition={isUnassignRecordsDisabled}
                                    message={unassignTooltipMessage}
                                    element={
                                        <button
                                            className='btn btn-warning'
                                            disabled={isUnassignRecordsDisabled}
                                            onClick={() => handleUnassignRecords()}>
                                            Unassign All {isUnassigningRecords && <Loading sm={true} />}
                                        </button>
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Assigned Suppliers</th>
                            <td className='d-flex justify-content-between'>
                                {user.assignedSupplierCount}
                                <TooltipWrapper
                                    condition={isUnassignSuppliersDisabled}
                                    message={unassignTooltipMessage}
                                    element={
                                        <button
                                            className='btn btn-warning'
                                            disabled={isUnassignSuppliersDisabled}
                                            onClick={() => handleUnassignSuppliers()}>
                                            Unassign All {isUnassigningSuppliers && <Loading sm={true} />}
                                        </button>
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
