import React, { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { batchUpdateRecords } from '../../api'
import { User } from '../../models'
import { QueryParams } from '../common/paginatedTable'
import { Loading } from '../common'

interface RecordParams extends QueryParams {
    record_status_code: string
}

interface AssignRecordsFormProps {
    queryParams: RecordParams
    getRecordCount: (queryParams: QueryParams) => Promise<number>
    recordType?: string
    users: User[]
}

const MAX_RECORDS_TO_ASSIGN = 500

export const AssignRecordsForm = (props: AssignRecordsFormProps) => {
    const { queryParams, getRecordCount, recordType, users } = props

    const [assignedUserId, setAssignedUserId] = useState<string>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isFetchingRecordCount, setIsFetchingRecordCount] = useState(false)
    const [recordCount, setRecordCount] = useState<number | null>(null)

    useEffect(() => {
        let isCancelled = false

        setIsFetchingRecordCount(true)
        getRecordCount(queryParams)
            .then((response: any) => {
                if (!isCancelled) {
                    setIsFetchingRecordCount(false)
                    setRecordCount(response.data.count)
                }
            })

        return () => {
            isCancelled = true
        }
    }, [JSON.stringify(queryParams)])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (assignedUserId && recordType) {
            const params = {
                assigned_user_id: {
                    oldValue: JSON.stringify({
                        ...queryParams?.advancedSearch,
                        status: queryParams?.record_status_code
                    }),
                    newValue: assignedUserId
                }
            }

            setIsSubmitting(true)

            batchUpdateRecords(recordType, params)
                .then(() => {
                    setIsSubmitting(false)
                    setIsFetchingRecordCount(true)
                    getRecordCount(queryParams)
                        .then((response: any) => {
                            setIsFetchingRecordCount(false)
                            setRecordCount(response.data.count)
                        })
                    toast.success('Records Assigned')
                })
                .catch(error => toast.error(error, { autoClose: false }))
        }
    }

    const getRecordCountToAssign = () => {
        return recordCount !== null && recordCount < MAX_RECORDS_TO_ASSIGN ? recordCount : MAX_RECORDS_TO_ASSIGN
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="d-flex">
            <div style={{ marginRight: '20px' }}>
                <label htmlFor='usersInput'>
                    Assign Records to User
                </label>
                <select
                    className='form-select mb-2'
                    id='usersInput'
                    onChange={(e) => setAssignedUserId(e.target.value)}
                    required>
                    <option value=''>
                        Select User
                    </option>
                    {users.map(user => (
                        <option key={`user_option_${user.id}`} value={user.id}>
                            {user.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <button
                    className='btn btn-primary'
                    style={{ marginTop: '22px' }}
                    disabled={isSubmitting || recordCount === 0}
                    type='submit'>
                    Assign {getRecordCountToAssign()} of {isFetchingRecordCount ? <Loading sm={true} /> : recordCount} unassigned {isSubmitting && <Loading sm={true} />}
                </button>
            </div>
        </form>
    )
}
