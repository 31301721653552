import React from 'react'
import { toast } from 'react-toastify'

import { Record } from '../../../models'
import { batchUpdateRecords } from '../../../api'
import { PaginatedTableContract } from '../../common'
import { InvalidSupplierRow } from './row'

interface InvalidSupplierProps extends PaginatedTableContract {
    resources: {
        records: Record[]
    }
}

export const InvalidSupplierTable = (props: InvalidSupplierProps) => {
    const { resources, callbacks } = props
    const { records } = resources
    const { setReload } = callbacks

    const handleSubmit = (oldValue: string, newValue: string) => {
        const params = {
            phone_number: {
                oldValue,
                newValue
            }
        }

        batchUpdateRecords('default' , params)
            .then(response => {
                if (response.status === 200 && setReload) {
                    toast.success('Phone Number Updated')
                    setReload(true)
                }
            })
            .catch(error => {
                const { response } = error
                const { message } = response.data

                if (message) {
                    toast.error(message, { autoClose: false })
                } else {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Record ID</th>
                    <th>Name</th>
                    <th>Street</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                    <th colSpan={2}>Phone</th>
                </tr>
                {records.map(record =>
                    <InvalidSupplierRow
                        key={record.id}
                        onSubmit={handleSubmit}
                        record={record} />
                )}
            </tbody>
        </table>
    )
}
